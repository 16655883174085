import "./card-topper.scss";

import { ReactNode } from "react";
import cn from "clsx";

import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import {
  CtaList,
  CtaListProps,
} from "design-system/components/primitives/cta-list/cta-list";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import { Theme, BackgroundColors } from "design-system/utils/theme";
import { EditAttributes } from "design-system/types/types";
import { shouldDecreaseTextSize } from "design-system/utils/text";

export interface CardTopperProps {
  type: "Card";
  title: string;
  subtitle?: ReactNode;
  media?: MediaAssetProps;
  ctaList?: CtaListProps;
  ctaButton?: CtaLinkProps;
  theme?: BackgroundColors;
  spotTheme?: Theme;

  editAttributes?: {
    title?: EditAttributes;
    subtitle?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - A [landing page](/story/example-pages-landing-pages-card-topper--story)
 *
 * - **`id: CT-001-001-00`**
 * - **`data-region: ct__page__card`**
 */
export function CardTopper({
  title,
  subtitle,
  media,
  ctaList,
  ctaButton,
  theme,
  spotTheme = "crimson",
  editAttributes,
}: CardTopperProps) {
  const headingClass = cn(
    "hbs-card-topper__heading",
    shouldDecreaseTextSize(title, 45, 15) && "hbs-card-topper__heading--long",
  );

  if (media?.image) {
    media.image.loading = "eager";
  }

  return (
    <div
      className="hbs-topper-wrapper hbs-card-topper__container"
      data-theme={theme}
    >
      <div className="hbs-card-topper" data-region="ct__page__card">
        <div className="hbs-card-topper__content">
          <div className="hbs-card-topper__inner" data-theme={spotTheme}>
            <div className="hbs-card-topper__wrapper">
              <h1 className={headingClass} {...editAttributes?.title}>
                {title}
              </h1>
              {subtitle && (
                <div
                  className="hbs-card-topper__subheading"
                  {...editAttributes?.subtitle}
                >
                  {subtitle}
                </div>
              )}

              <div className="hbs-card-topper__cta">
                {ctaList && !ctaButton && <CtaList {...ctaList} />}
                {ctaButton && (
                  <CtaLink type="primary-button" href={ctaButton.href}>
                    {ctaButton.children}
                  </CtaLink>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hbs-card-topper__art">
          {media && <MediaAsset {...media} aspectRatio="3/2" />}
        </div>
      </div>
    </div>
  );
}
